/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    em: "em",
    a: "a",
    h3: "h3",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components), {CodePen} = _components;
  if (!CodePen) _missingMdxReference("CodePen", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Quill's core strength as an editor is its rich API and powerful customization capabilities. As you implement functionality on top of Quill's API, it may be convenient to organize this as a module. For the purpose of this guide, we will walk through one way to build a word counter module, a commonly found feature in many word processors."), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Note: Internally modules are how much of Quill's functionality is organized. You can overwrite these default ", React.createElement(_components.a, {
    href: "/docs/modules/"
  }, "modules"), " by implementing your own and registering it with the same name.")), "\n", React.createElement(_components.h3, null, "Counting Words"), "\n", React.createElement(_components.p, null, "At its core a word counter simply counts the number of words in the editor and displays this value in some UI. Thus we need to:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Listen for text changes in Quill."), "\n", React.createElement(_components.li, null, "Count the number of words."), "\n", React.createElement(_components.li, null, "Display this value."), "\n"), "\n", React.createElement(_components.p, null, "Let's jump straight in with a complete example!"), "\n", React.createElement(CodePen, {
    height: "379",
    hash: "bZkWKA"
  }), "\n", React.createElement(_components.p, null, "That's all it takes to add a custom module to Quill! A function can be ", React.createElement(_components.a, {
    href: "/docs/api/#quillregistermodule/"
  }, "registered"), " as a module and it will be passed the corresponding Quill editor object along with any options."), "\n", React.createElement(_components.h3, null, "Using Options"), "\n", React.createElement(_components.p, null, "Modules are passed an options object that can be used to fine tune the desired behavior. We can use this to accept a selector for the counter container instead of a hard-coded string. Let's also customize the counter to either count words or characters:"), "\n", React.createElement(CodePen, {
    height: "430",
    hash: "OXqmEp"
  }), "\n", React.createElement(_components.h3, null, "Constructors"), "\n", React.createElement(_components.p, null, "Since any function can be registered as a Quill module, we could have implemented our counter as an ES5 constructor or ES6 class. This allows us to access and utilize the module directly."), "\n", React.createElement(CodePen, {
    height: "688",
    hash: "BzbRVR"
  }), "\n", React.createElement(_components.h3, null, "Wrapping It All Up"), "\n", React.createElement(_components.p, null, "Now let's polish off the module in ES6 and fix a few pesky bugs. That's all there is to it!"), "\n", React.createElement(CodePen, {
    height: "772",
    hash: "wWOdXZ"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
